import styles from "./Separator.module.scss";
import React from "react";

interface SeparatorProps {
  step: number;
  isFirst: boolean;
  isLast: boolean;
}

export const Separator = ({ step, isFirst, isLast }: SeparatorProps) => {
  return (
    <div className={styles.separatorContainer}>
      {isFirst && <div className={styles.circle} />}
      <div className={styles.line} />
      <h3 className={styles.stepText}>Steg {step}</h3>
      <div className={styles.line} />
      {isLast && <div className={styles.circle} />}
    </div>
  );
};
