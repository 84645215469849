import React from "react";
import styles from "./EventCTA.module.css";
import Button from "../../../../components/Button";

interface EventCTAProps {
  title: string;
  signupLink?: string;
}

const EventCTA: React.FC<EventCTAProps> = ({ title, signupLink }) => {
  return (
    <div className={styles.ctaSection}>
      <div className={styles.ctaContent}>
        <h2>Bli med på {title}</h2>
        <p>
          Meld deg på dette arrangementet og bli med på en inspirerende dag med
          faglig påfyll og nettverksbygging.
        </p>
        {signupLink ? (
          <Button
            color="norway-sea"
            size="medium"
            href={signupLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Meld deg på arrangementet
          </Button>
        ) : (
          <Button color="norway-sea" size="medium">
            Meld deg på arrangementet
          </Button>
        )}
      </div>
    </div>
  );
};

export default EventCTA;
