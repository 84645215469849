/**
 * Interface for calendar event data
 */
export interface CalendarEvent {
  title: string;
  description: string;
  location: string;
  startTime: Date;
  endTime: Date;
}

/**
 * Get a Google Calendar URL for the event
 */
export function getGoogleCalendarUrl(event: CalendarEvent): string {
  // For Google Calendar, format dates without timezone conversion
  // Google Calendar expects dates in YYYYMMDDTHHMMSSZ format for UTC
  const formatGoogleDate = (date: Date) => {
    // Log the date being processed
    console.log("Google Calendar date input:", date.toString());
    console.log("Input date ISO:", date.toISOString());

    // Make sure we're using UTC time consistently
    const isoString = date.toISOString();

    // Remove separators and milliseconds as required by Google Calendar
    // Format should be: YYYYMMDDTHHMMSSZ
    const formattedDate = isoString.replace(/-|:|\.\d+/g, "");

    console.log("Formatted date for Google Calendar:", formattedDate);
    return formattedDate;
  };

  const startTimeISO = formatGoogleDate(event.startTime);
  const endTimeISO = formatGoogleDate(event.endTime);

  // Log the final URL params
  console.log("Google Calendar date parameters:", { startTimeISO, endTimeISO });

  // Build the calendar URL
  const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    event.title
  )}&dates=${startTimeISO}/${endTimeISO}&details=${encodeURIComponent(
    event.description
  )}&location=${encodeURIComponent(event.location)}&sprop=&sprop=name:`;

  console.log("Final Google Calendar URL:", calendarUrl);

  return calendarUrl;
}

/**
 * Get a Microsoft Outlook URL for the event
 */
export function getOutlookCalendarUrl(event: CalendarEvent): string {
  // For Outlook, use the full ISO format which includes timezone information
  console.log("Outlook Calendar dates:", {
    start: event.startTime.toISOString(),
    end: event.endTime.toISOString(),
  });

  return `https://outlook.live.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(
    event.title
  )}&startdt=${event.startTime.toISOString()}&enddt=${event.endTime.toISOString()}&body=${encodeURIComponent(
    event.description
  )}&location=${encodeURIComponent(event.location)}`;
}

/**
 * Get a Yahoo Calendar URL for the event
 */
export function getYahooCalendarUrl(event: CalendarEvent): string {
  // For Yahoo Calendar, format dates in UTC without separators
  const yahooDateFormat = (date: Date) => {
    console.log("Yahoo Calendar date input:", date.toString());
    return date.toISOString().replace(/-|:|\.\d+/g, "");
  };

  const startTimeISO = yahooDateFormat(event.startTime);
  const endTimeISO = yahooDateFormat(event.endTime);

  console.log("Yahoo Calendar formatted dates:", { startTimeISO, endTimeISO });

  return `https://calendar.yahoo.com/?v=60&title=${encodeURIComponent(
    event.title
  )}&st=${startTimeISO}&et=${endTimeISO}&desc=${encodeURIComponent(
    event.description
  )}&in_loc=${encodeURIComponent(event.location)}`;
}

/**
 * Create an iCalendar file content
 */
export function getICalContent(event: CalendarEvent): string {
  // For iCal, we need to use UTC format (ending with Z)
  const formatDate = (date: Date) => {
    console.log("iCal date input:", date.toString());
    // Get UTC time in the format required by iCal
    const formattedDate =
      date
        .toISOString()
        .replace(/-|:|\.\d+/g, "")
        .substring(0, 15) + "Z";

    console.log("iCal formatted date:", formattedDate);
    return formattedDate;
  };

  return `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
PRODID:-//Yne Consulting//Tekfyret Calendar//EN
METHOD:PUBLISH
BEGIN:VEVENT
DTSTART:${formatDate(event.startTime)}
DTEND:${formatDate(event.endTime)}
DTSTAMP:${formatDate(new Date())}
SUMMARY:${event.title}
DESCRIPTION:${event.description}
LOCATION:${event.location}
END:VEVENT
END:VCALENDAR`;
}

/**
 * Create and download an iCalendar file
 */
export function downloadICalFile(event: CalendarEvent): void {
  const content = getICalContent(event);
  const element = document.createElement("a");
  const file = new Blob([content], { type: "text/calendar" });

  element.href = URL.createObjectURL(file);
  element.download = `${event.title.replace(/\s+/g, "-")}.ics`;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

/**
 * Parse a date string in Norwegian format to a JavaScript Date object
 * @param dateString Date string in Norwegian format (e.g., "25. januar 2023")
 * @param timeString Time string (e.g., "18:00")
 * @returns JavaScript Date object
 */
export function parseNorwegianDate(
  dateString: string,
  timeString?: string
): Date {
  try {
    console.log("Parsing date:", dateString, "time:", timeString);

    // Default to current date if no date string is provided
    if (!dateString || dateString === "Dato ikke satt") {
      console.log("Using default date (today)");
      return new Date();
    }

    // Parse Norwegian month names
    const monthMap: Record<string, number> = {
      januar: 0,
      februar: 1,
      mars: 2,
      april: 3,
      mai: 4,
      juni: 5,
      juli: 6,
      august: 7,
      september: 8,
      oktober: 9,
      november: 10,
      desember: 11,
    };

    // Extract day, month, year
    const dateParts = dateString.replace(".", "").split(" ");
    console.log("Date parts:", dateParts);

    if (dateParts.length < 3) {
      console.error("Invalid date format:", dateString);
      return new Date(); // Return current date as fallback
    }

    const day = parseInt(dateParts[0], 10);
    const monthLower = dateParts[1].toLowerCase();
    const month = monthMap[monthLower];

    if (month === undefined) {
      console.error("Invalid month:", monthLower);
      return new Date(); // Return current date as fallback
    }

    const year = parseInt(dateParts[2], 10);

    if (isNaN(day) || isNaN(year)) {
      console.error("Invalid day or year:", day, year);
      return new Date(); // Return current date as fallback
    }

    // Create date object with UTC values to avoid timezone issues
    // Using UTC methods ensures the date will be correctly converted to local time
    const date = new Date(Date.UTC(year, month, day));

    // Check if date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date created from:", year, month, day);
      return new Date(); // Return current date as fallback
    }

    // Add time if provided
    if (timeString) {
      const timeParts = timeString.split(":");
      if (timeParts.length >= 2) {
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);

        if (!isNaN(hours) && !isNaN(minutes)) {
          // Set hours and minutes using UTC methods to avoid timezone shifts
          date.setUTCHours(hours, minutes, 0, 0);
        } else {
          console.warn("Invalid time parts:", hours, minutes);
          date.setUTCHours(12, 0, 0, 0); // Default to noon
        }
      } else {
        console.warn("Invalid time format:", timeString);
        date.setUTCHours(12, 0, 0, 0); // Default to noon
      }
    } else {
      // Default to noon if no time specified
      date.setUTCHours(12, 0, 0, 0);
    }

    console.log("Parsed date:", date, "ISO string:", date.toISOString());
    return date;
  } catch (error) {
    console.error("Error parsing date:", error);
    return new Date(); // Return current date as fallback
  }
}

/**
 * Calculate the end time for an event based on start time
 * @param startTime Event start time
 * @param durationHours Duration in hours (default: 2)
 * @returns End time
 */
export function calculateEndTime(startTime: Date, durationHours = 2): Date {
  const endTime = new Date(startTime);

  // Use UTC methods for consistent time handling
  endTime.setUTCHours(endTime.getUTCHours() + durationHours);

  return endTime;
}
