/**
 * Interface for social media sharing URLs
 */
export interface ShareUrls {
  currentUrl: string;
  facebook: string;
  twitter: string;
  linkedin: string;
}

/**
 * Creates social media sharing URLs based on the provided event information and current URL
 *
 * @param event The event object containing title and description
 * @param currentUrl The current page URL
 * @returns Object containing URLs for Facebook, Twitter, LinkedIn, and the current URL
 */
export function createShareUrls(
  event: { title?: string; description?: string } | null,
  currentUrl: string
): ShareUrls {
  const encodedUrl = encodeURIComponent(currentUrl);
  const encodedTitle = encodeURIComponent(
    event?.title || "Spennende arrangement hos Yne!"
  );
  const encodedDescription = encodeURIComponent(
    event?.description || "Se dette spennende arrangementet hos Yne Consulting"
  );
  const encodedHashtags = "yne,tekfyret";

  return {
    currentUrl,
    // Facebook uses sharer.php with u parameter for URL
    facebook: `https://www.facebook.com/sharer.php?u=${encodedUrl}&quote=${encodedTitle}: ${encodedDescription}`,

    // X (formerly Twitter) supports url, text, hashtags, and via parameters
    twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}&hashtags=${encodedHashtags}&via=YneConsulting`,

    // LinkedIn using shareArticle format with more parameters
    linkedin: `https://www.linkedin.com/shareArticle?url=${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}&source=YneConsulting`,
  };
}

/**
 * Safely gets the current URL, works in both browser and SSR environments
 *
 * @param fallbackSlug The event slug to use in the fallback URL
 * @returns The current URL or a fallback URL
 */
export function getCurrentUrl(fallbackSlug: string): string {
  if (typeof window !== "undefined") {
    return window.location.href;
  }
  return `https://yne.no/tekfyret/${fallbackSlug}`;
}
