import React, { ReactNode } from "react";
import styles from "../event-details.module.css";
import Button from "../../../components/Button";

interface StatusHandlerProps {
  isLoading: boolean;
  error: string | null;
  hasImage: boolean;
  hasEvent: boolean;
  children: ReactNode;
}

/**
 * Component for handling loading, error, and image validation states
 */
const StatusHandler: React.FC<StatusHandlerProps> = ({
  isLoading,
  error,
  hasImage,
  hasEvent,
  children,
}) => {
  if (isLoading) {
    return <div className={styles.loading}>Loading event details...</div>;
  }

  if (error || !hasEvent) {
    return (
      <div className={styles.notFound}>
        <h1>Event ikke funnet</h1>
        <p>Beklager, vi kunne ikke finne arrangementet du leter etter.</p>
        <Button to="/tekfyret" color="norway-sea" size="medium">
          Tilbake til alle arrangementer
        </Button>
      </div>
    );
  }

  if (!hasImage) {
    return (
      <div className={styles.notFound}>
        <h1>Bilde mangler</h1>
        <p>Dette arrangementet mangler bilde og kan ikke vises.</p>
        <Button to="/tekfyret" color="norway-sea" size="medium">
          Tilbake til alle arrangementer
        </Button>
      </div>
    );
  }

  return <>{children}</>;
};

export default StatusHandler;
