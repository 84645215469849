import React from "react";
import styles from "./EventSidebar.module.css";
import SidebarEventCard from "../../../../components/eventCardSmall/SidebarEventCard";
import Button from "../../../../components/Button";
import CalendarButtons from "../calendarbuttons/CalendarButtons";
import { CalendarEvent } from "../../utils/calendarUtils";
import ContactPersonsSection from "../contactPersonsSection/ContactPersonsSection";
import ShareButtons from "../shareButtons/ShareButtons";

interface ShareUrls {
  currentUrl: string;
  facebook: string;
  twitter: string;
  linkedin: string;
}

interface RelatedEvent {
  _id?: string;
  slug?: {
    current?: string;
  };
  title?: string;
  date?: string;
  image?: {
    asset?: {
      _id?: string;
      url?: string;
    };
  };
}

interface ContactPerson {
  _id: string;
  name: string;
  email?: string;
  phone?: string;
  title?: string;
  image?: {
    asset?: {
      _id?: string;
      url?: string;
    };
    alt?: string;
  };
}

interface EventSidebarProps {
  shareUrls: ShareUrls;
  relatedEvents: RelatedEvent[];
  linkCopied: boolean;
  onCopyLink: () => void;
  calendarEvent?: CalendarEvent;
  contacts?: ContactPerson[];
}

const EventSidebar: React.FC<EventSidebarProps> = ({
  shareUrls,
  relatedEvents,
  linkCopied,
  onCopyLink,
  calendarEvent,
  contacts,
}) => {
  const hasContacts = contacts && contacts.length > 0;

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarWidget}>
        <h3>Del arrangementet</h3>
        <ShareButtons
          shareUrls={shareUrls}
          linkCopied={linkCopied}
          onCopyLink={onCopyLink}
        />
      </div>

      {calendarEvent && (
        <div className={styles.sidebarWidget}>
          <h3>Legg til i kalender</h3>
          <CalendarButtons event={calendarEvent} />
        </div>
      )}

      <div className={styles.sidebarWidget}>
        <h3>Kommende arrangementer</h3>
        <div className={styles.upcomingEvents}>
          {relatedEvents.length > 0 ? (
            relatedEvents
              .filter(
                (relatedEvent) =>
                  relatedEvent.image &&
                  relatedEvent.image.asset &&
                  relatedEvent.image.asset.url
              )
              .map((relatedEvent, index) => (
                <SidebarEventCard
                  key={index}
                  id={relatedEvent.slug?.current || ""}
                  title={relatedEvent.title || ""}
                  date={relatedEvent.date || ""}
                  image={relatedEvent.image!.asset!.url!}
                />
              ))
          ) : (
            <p>Ingen andre arrangementer for øyeblikket.</p>
          )}
        </div>
      </div>

      {hasContacts ? (
        <ContactPersonsSection contacts={contacts} />
      ) : (
        <div className={styles.sidebarWidget}>
          <h3>Kontakt oss</h3>
          <p className={styles.contactText}>
            Har du spørsmål om dette arrangementet eller andre
            Tekfyret-arrangementer?
          </p>
          <Button
            href="mailto:tekfyret@yne.no"
            color="secondary"
            variant="outline"
            size="medium"
            className={styles.contactButtonBlock}
          >
            Kontakt oss
          </Button>
        </div>
      )}
    </div>
  );
};

export default EventSidebar;
