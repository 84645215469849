import React, { ReactNode } from "react";
import styles from "./TimelineStep.module.scss";
import { Separator } from "./Separator";

export type TimelineStep = {
  step: number;
  title: string;
  description: ReactNode;
  image: string;
  isFirst?: boolean;
  isLast?: boolean;
};

export const TimelineStep = ({
  step,
  title,
  description,
  image,
  isFirst = false,
  isLast = false,
}: TimelineStep) => {
  return (
    <div className={styles.stepWrapper}>
      {/* Alternate text and image positioning */}
      {step % 2 === 0 ? (
        <>
          <div className={styles.contentLeft}>
            <img src={image} alt={title} className={styles.image} />
          </div>
          <Separator step={step} isFirst={isFirst} isLast={isLast} />
          <div className={styles.contentRight}>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.description}>{description}</div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.contentLeft}>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.description}>{description}</div>
          </div>
          <Separator step={step} isFirst={isFirst} isLast={isLast} />
          <div className={styles.contentRight}>
            <img src={image} alt={title} className={styles.image} />
          </div>
        </>
      )}
    </div>
  );
};
