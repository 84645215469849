import {
  parseNorwegianDate,
  calculateEndTime,
  CalendarEvent,
} from "./calendarUtils";

/**
 * Event interface with all possible date/time fields
 */
interface EventWithDates {
  title: string;
  description: string;
  location: string;
  startTime?: string;
  endTime?: string;
  date?: string;
  time?: string;
}

/**
 * Creates a calendar event object from an event
 * Handles various date formats and fallbacks
 *
 * @param event The event object containing date and time information
 * @returns A CalendarEvent object or undefined if event is invalid
 */
export function createCalendarEvent(
  event: EventWithDates | null
): CalendarEvent | undefined {
  if (!event) {
    return undefined;
  }

  let startTime: Date;
  let endTime: Date;

  // Handle start time
  if (event.startTime) {
    // Parse startTime string into a Date object
    try {
      const startTimeString = event.startTime;
      startTime = new Date(startTimeString);

      // Validate the date is valid
      if (isNaN(startTime.getTime())) {
        console.error("Invalid startTime:", startTimeString);
        startTime = new Date(); // Fallback to current date
      }
    } catch (error) {
      console.error("Error parsing startTime:", error);
      startTime = new Date(); // Fallback to current date
    }
  } else if (event.date && event.time) {
    // Fallback to old fields
    startTime = parseNorwegianDate(event.date, event.time);
  } else {
    console.warn("No valid start time for event:", event.title);
    startTime = new Date();
  }

  // Handle end time
  if (event.endTime) {
    // Parse endTime string into a Date object
    try {
      const endTimeString = event.endTime;
      endTime = new Date(endTimeString);

      // Validate the date is valid
      if (isNaN(endTime.getTime())) {
        console.error("Invalid endTime:", endTimeString);
        // Fallback to start time + 2 hours
        endTime = calculateEndTime(startTime, 2);
      }
    } catch (error) {
      console.error("Error parsing endTime:", error);
      // Fallback to start time + 2 hours
      endTime = calculateEndTime(startTime, 2);
    }
  } else {
    // If no end time is provided, calculate one based on start time
    endTime = calculateEndTime(startTime, 2);
  }

  return {
    title: event.title,
    description: event.description,
    location: event.location,
    startTime,
    endTime,
  };
}
