import React from "react";
import styles from "./EventAgenda.module.css";

interface AgendaItem {
  time?: string;
  title?: string;
}

interface EventAgendaProps {
  agenda: AgendaItem[];
}

const EventAgenda: React.FC<EventAgendaProps> = ({ agenda }) => {
  if (!agenda || agenda.length === 0) {
    return null;
  }

  return (
    <section className={styles.agenda}>
      <h2>Program</h2>
      <div className={styles.agendaTimeline}>
        {agenda
          .filter((item) => item != null) // Filter out null items
          .map((item, index) => (
            <div key={index} className={styles.agendaItem}>
              <p className={styles.agendaTime}>{item?.time || ""}</p>
              <p className={styles.agendaDot}></p>
              <p className={styles.agendaTitle}>{item?.title || ""}</p>
            </div>
          ))}
      </div>
    </section>
  );
};

export default EventAgenda;
