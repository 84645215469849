import React from "react";
import styles from "./ContactPersonsSection.module.css";
import ContactPersons from "../contactpersons/ContactPersons";

interface ContactPerson {
  _id: string;
  name: string;
  email?: string;
  phone?: string;
  title?: string;
  image?: {
    asset?: {
      _id?: string;
      url?: string;
    };
    alt?: string;
  };
}

interface ContactPersonsSectionProps {
  contacts: ContactPerson[];
  title?: string;
}

const ContactPersonsSection: React.FC<ContactPersonsSectionProps> = ({
  contacts,
  title = "Kontaktpersoner",
}) => {
  if (!contacts || contacts.length === 0) {
    return null;
  }

  return (
    <div className={styles.contactPersonsSection}>
      <h3>{title}</h3>
      <ContactPersons contacts={contacts} />
    </div>
  );
};

export default ContactPersonsSection;
