import React, { useEffect, useRef } from "react";
import styles from "./LightHouseHeader.module.css";

interface ExperimentalHeaderProps {
  title: React.ReactNode;
  subtitle: string;
  backgroundImage?: string;
  variant?: "default" | "parallax" | "video" | "particles";
}

const LightHouseHeader: React.FC<ExperimentalHeaderProps> = ({
  title,
  subtitle,
  backgroundImage = "../../assets/images/lighthouse.png",
  variant = "default",
}) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const headerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    // Title animation
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animateTitle);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    // Parallax effect for 'parallax' variant
    if (variant === "parallax" && headerRef.current) {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (headerRef.current) {
          headerRef.current.style.backgroundPositionY = `calc(10% + ${
            scrollPosition * 0.5
          }px)`;
        }
      };

      window.addEventListener("scroll", handleScroll);

      // Letter illumination animation
      const illuminateLetters = () => {
        const tekPartElement = document.querySelector(`.${styles.tekPart}`);
        const fyretPartElement = document.querySelector(`.${styles.fyretPart}`);

        if (tekPartElement && fyretPartElement) {
          const tekLetters = tekPartElement.querySelectorAll("span");
          const fyretLetters = fyretPartElement.querySelectorAll("span");

          // Function to sequentially illuminate letters
          const illuminateSequence = (
            letters: NodeListOf<Element>,
            startDelay: number
          ) => {
            letters.forEach((letter, index) => {
              setTimeout(() => {
                letter.classList.add(styles.illuminated);

                // Remove class after animation completes
                setTimeout(() => {
                  letter.classList.remove(styles.illuminated);
                }, 800);
              }, startDelay + index * 150); // Stagger the animations
            });
          };

          // Trigger illuminations with appropriate timing
          const startTekIllumination = 2000; // 2 seconds into the cycle
          const startFyretIllumination = 4000; // 4 seconds into the cycle

          illuminateSequence(tekLetters, startTekIllumination);
          illuminateSequence(fyretLetters, startFyretIllumination);
        }
      };

      // Start sequence after initial load
      const initialTimeout = setTimeout(illuminateLetters, 1500);

      // Set up interval to repeat the sequence
      const interval = setInterval(illuminateLetters, 8000);

      return () => {
        window.removeEventListener("scroll", handleScroll);
        if (titleRef.current) {
          observer.unobserve(titleRef.current);
        }
        clearTimeout(initialTimeout);
        clearInterval(interval);
      };
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, [variant]);

  const headerStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };

  const headerClasses = `${styles.heroHeader} ${styles[variant]}`;

  // Split title text into individual letter spans for parts
  const createLetterSpans = (text: string) => {
    return text
      .split("")
      .map((letter, index) => <span key={index}>{letter}</span>);
  };

  // Render the styled title (for animations) or use the custom title if provided
  const renderTitle = () => {
    // Check if title is provided and use it directly if it's a custom element
    if (title && typeof title !== "string") {
      return title;
    }

    // Default animated title structure without hashtag
    return (
      <>
        <span className={styles.tekPart}>{createLetterSpans("Tek")}</span>
        <span className={styles.fyretPart}>{createLetterSpans("Fyret")}</span>
      </>
    );
  };

  return (
    <header ref={headerRef} className={headerClasses} style={headerStyle}>
      <div className={styles.heroOverlay}></div>

      {variant === "particles" && (
        <div className={styles.particles}>
          {[...Array(20)].map((_, i) => (
            <div key={i} className={styles.particle}></div>
          ))}
        </div>
      )}

      <div className={styles.heroContent}>
        <h1 ref={titleRef} className={styles.heroTitle}>
          {renderTitle()}
        </h1>
        <p className={styles.heroSubtitle}>{subtitle}</p>
      </div>

      <div className={styles.waveSvg}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="var(--colored-background)"
            fillOpacity="1"
            d="M0,224L48,213.3C96,203,192,181,288,154.7C384,128,480,96,576,106.7C672,117,768,171,864,186.7C960,203,1056,181,1152,165.3C1248,149,1344,139,1392,133.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </header>
  );
};

export default LightHouseHeader;
