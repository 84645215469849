import React, { useEffect } from "react";
const InstagramFeed = () => {
  useEffect(() => {
    // LightWidget trenger dette scriptet for å fungere
    const script = document.createElement("script");
    script.src = "https://cdn.lightwidget.com/widgets/lightwidget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Rydd opp om komponenten fjernes
      document.body.removeChild(script);
    };
  }, []);

  return (
    <iframe
      title="Instagram Feed"
      src="https://lightwidget.com/widgets/ff860ee598c757f892ac13db2df1cc4f.html"
      scrolling="no"
      allow="transparency"
      className="lightwidget-widget"
      style={{
        width: "100%",
        border: 0,
        overflow: "hidden",
      }}
    ></iframe>
  );
};

export default InstagramFeed;
