import React, { useEffect, useState } from "react";
import {
  getGoogleCalendarUrl,
  getOutlookCalendarUrl,
  getYahooCalendarUrl,
  downloadICalFile,
  CalendarEvent,
} from "../../utils/calendarUtils";
import styles from "./CalendarButtons.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { faApple } from "@fortawesome/free-brands-svg-icons";

interface CalendarButtonsProps {
  event: CalendarEvent;
}

const CalendarButtons: React.FC<CalendarButtonsProps> = ({ event }) => {
  const [calendarUrls, setCalendarUrls] = useState<{
    google: string;
    outlook: string;
    yahoo: string;
  }>({ google: "#", outlook: "#", yahoo: "#" });
  const [hasValidDates, setHasValidDates] = useState(true);

  useEffect(() => {
    // Validate the dates before trying to generate URLs
    const isValidDate = (date: Date) => !isNaN(date.getTime());

    if (isValidDate(event.startTime) && isValidDate(event.endTime)) {
      try {
        setCalendarUrls({
          google: getGoogleCalendarUrl(event),
          outlook: getOutlookCalendarUrl(event),
          yahoo: getYahooCalendarUrl(event),
        });
        setHasValidDates(true);
      } catch (error) {
        console.error("Error generating calendar URLs:", error);
        setHasValidDates(false);
      }
    } else {
      console.error("Invalid date objects in calendar event:", event);
      setHasValidDates(false);
    }
  }, [event]);

  const handleICalDownload = () => {
    try {
      downloadICalFile(event);
    } catch (error) {
      console.error("Error downloading iCal file:", error);
      alert("Det oppstod en feil ved nedlasting av kalenderfilen.");
    }
  };

  if (!hasValidDates) {
    return (
      <div className={styles.calendarButtonsError}>
        <p>Kunne ikke laste kalenderdata</p>
      </div>
    );
  }

  return (
    <div className={styles.calendarButtons}>
      <a
        href={calendarUrls.google}
        target="_blank"
        rel="noopener noreferrer"
        className={`${styles.calendarIcon} ${styles.google}`}
        aria-label="Legg til i Google Kalender"
      >
        <FontAwesomeIcon icon={faGoogle} />
      </a>

      <a
        href={calendarUrls.outlook}
        target="_blank"
        rel="noopener noreferrer"
        className={`${styles.calendarIcon} ${styles.outlook}`}
        aria-label="Legg til i Outlook Kalender"
      >
        <FontAwesomeIcon icon={faMicrosoft} />
      </a>

      <button
        onClick={handleICalDownload}
        className={`${styles.calendarIcon} ${styles.apple}`}
        aria-label="Last ned iCal fil for Apple Kalender"
      >
        <FontAwesomeIcon icon={faApple} />
      </button>
    </div>
  );
};

export default CalendarButtons;
