import React from "react";
import styles from "./ContactPersons.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

interface ContactPersonProps {
  contacts: {
    _id: string;
    name: string;
    email?: string;
    phone?: string;
    title?: string;
    image?: {
      asset?: {
        _id?: string;
        url?: string;
      };
      alt?: string;
    };
  }[];
}

const ContactPersons: React.FC<ContactPersonProps> = ({ contacts }) => {
  if (!contacts || contacts.length === 0) {
    return null;
  }

  return (
    <div className={styles.contactPersonsGrid}>
      {contacts.map((contact) => {
        return (
          <div key={contact._id} className={styles.contactCard}>
            <div className={styles.contactImageWrapper}>
              {contact.image &&
              contact.image.asset &&
              contact.image.asset.url ? (
                <img
                  src={contact.image.asset.url}
                  alt={contact.image.alt || `Bilde av ${contact.name}`}
                  className={styles.contactImage}
                />
              ) : (
                <div className={styles.contactImagePlaceholder}>
                  {contact.name.charAt(0)}
                </div>
              )}
            </div>
            <div className={styles.contactInfo}>
              <h4 className={styles.contactName}>{contact.name}</h4>
              {contact.title && (
                <p className={styles.contactTitle}>{contact.title}</p>
              )}
              <div className={styles.contactDetails}>
                {contact.email && (
                  <a
                    href={`mailto:${contact.email}`}
                    className={styles.contactLink}
                    aria-label={`Send e-post til ${contact.name}`}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span>{contact.email}</span>
                  </a>
                )}
                {contact.phone && (
                  <a
                    href={`tel:${contact.phone}`}
                    className={styles.contactLink}
                    aria-label={`Ring ${contact.name}`}
                  >
                    <FontAwesomeIcon icon={faPhone} />
                    <span>{contact.phone}</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContactPersons;
