import React from "react";
import styles from "./EventHero.module.css";
import Tag from "../../../../components/Tag";
import Image from "../../../../components/image/image";

interface EventHeroProps {
  title: string;
  backgroundImageUrl: string;
  tags?: any[];
  targetAudience?: any[];
}

const EventHero: React.FC<EventHeroProps> = ({
  title,
  backgroundImageUrl,
  tags = [],
  targetAudience = [],
}) => {
  return (
    <div className={styles.hero}>
      <div className={styles.heroBackground}>
        <Image
          src={backgroundImageUrl}
          alt={title}
          className={styles.backgroundImage}
        />
      </div>
      <div className={styles.heroOverlay}></div>
      <div className={styles.heroContent}>
        <h1 className={styles.eventTitle}>{title}</h1>
        <div className={styles.eventTags}>
          {tags.map((tag, index) => (
            <Tag key={`tag-${index}`} variant="large">
              {tag.name}
            </Tag>
          ))}
          {targetAudience.map((audience, index) => (
            <Tag key={`audience-${index}`} variant="large" color="green">
              {audience.name}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventHero;
