import React from "react";
import styles from "./ShareButtons.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faLink, faCheck } from "@fortawesome/free-solid-svg-icons";

interface ShareUrls {
  currentUrl: string;
  facebook: string;
  twitter: string;
  linkedin: string;
}

interface ShareButtonsProps {
  shareUrls: ShareUrls;
  linkCopied: boolean;
  onCopyLink: () => void;
}

const ShareButtons: React.FC<ShareButtonsProps> = ({
  shareUrls,
  linkCopied,
  onCopyLink,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.shareButtons}>
        <a
          href={shareUrls.facebook}
          className={`${styles.shareIcon} ${styles.facebook}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Del på Facebook"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a
          href={shareUrls.twitter}
          className={`${styles.shareIcon} ${styles.twitter}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Del på X (Twitter)"
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
        <a
          href={shareUrls.linkedin}
          className={`${styles.shareIcon} ${styles.linkedin}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Del på LinkedIn"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        <button
          onClick={onCopyLink}
          className={`${styles.shareIcon} ${styles.copyLink}`}
          aria-label="Kopier lenke"
        >
          <FontAwesomeIcon icon={linkCopied ? faCheck : faLink} />
        </button>
      </div>
      {linkCopied && (
        <p className={styles.copySuccess}>Lenke kopiert til utklippstavlen!</p>
      )}
    </div>
  );
};

export default ShareButtons;
