import React from "react";
import { Link } from "react-router-dom";

import styles from "./EventCard.module.css";
import Image from "../image";
import Tag from "../Tag";

interface EventCardProps {
  id: string;
  title: string;
  date: string;
  time?: string;
  description: string;
  image: string;
  tags: string[];
  targetAudience?: string[];
  linkPrefix?: string;
}

const EventCard: React.FC<EventCardProps> = ({
  id,
  title,
  date,
  time,
  description,
  image,
  tags,
  targetAudience = [],
  linkPrefix = "/event",
}) => {
  return (
    <Link to={`${linkPrefix}/${id}`} className={styles.eventCard}>
      <div className={styles.eventImageContainer}>
        <Image src={image} alt={title} className={styles.eventImage} />
      </div>
      <div className={styles.eventContent}>
        <p className={styles.eventDate}>
          {date}
          {time && `, ${time}`}
        </p>
        <h3 className={styles.eventTitle}>{title}</h3>
        <p className={styles.eventDescription}>{description}</p>
        <div className={styles.eventTags}>
          {tags.map((tag, index) => (
            <Tag key={`tag-${index}`}>{tag}</Tag>
          ))}
          {targetAudience.map((audience, index) => (
            <Tag key={`audience-${index}`} color="turquoise">
              {audience}
            </Tag>
          ))}
        </div>
      </div>
    </Link>
  );
};

export default EventCard;
