import React from "react";
import styles from "./Tag.module.css";

export interface TagProps {
  /**
   * Tag text content
   */
  children: React.ReactNode;
  /**
   * Optional CSS class name to override styles
   */
  className?: string;
  /**
   * Optional variant to apply different styling
   */
  variant?: "default" | "small" | "large";
  /**
   * Optional color to apply different styling
   */
  color?: "purple" | "green" | "turquoise";
}

/**
 * Reusable Tag component with norway-sea styling by default
 */
const Tag: React.FC<TagProps> = ({
  children,
  className = "",
  variant = "default",
  color = "purple",
}) => {
  return (
    <span
      className={`${styles.tag} ${styles[variant]} ${styles[color]} ${className}`}
    >
      {children}
    </span>
  );
};

export default Tag;
