import React, { useEffect } from "react";
import BlogFeed from "../../components/blog-feed";
import MetaHeader from "../../components/metaheader";
import { CLOUDINARY_IMG } from "../../helpers/const";
import { ProjectList } from "../projects/components/project-list";
// import { ReactComponent as VerdierImage } from "../../assets/images/verdier.svg";
import styles from "./about-us.module.scss";
import YoutubeEmbed from "../../components/youtube-embed";
import { Link } from "react-router-dom";
import PrimaryButton from "../../components/buttons/primary-button";
import Image from "../../components/image";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <MetaHeader
        description="I Yne er vi gode på mye, men vi vil være best på systemutvikling. Hos oss får man jobbe med kunder i mange bransjer, men felles er at de trenger en som kan håndtere komplekse oppgaver med stor trygghet, solid fagkompetanse og en fremoverlent holdning. Det kan en Yne! Les mer om hvem Yne er her."
        url="https://yne.no/om-oss"
        title="Hvem er Yne? | Yne"
        socialMediaDescription="Yne er en gjeng engasjerte FOLK som er møter hverandre med åpenhet, godt samhold og en flat struktur på arbeidsplassen!"
        imageUrl={CLOUDINARY_IMG.HOME.team_party}
      />
      <div className={`${styles.about}`}>
        <div>
          <section className={`section-container ${styles.leadImage}`}>
            {/* <VerdierImage /> */}
            <h3>I Yne er vi</h3>
            <div className={styles.values}>
              <div className={styles.proaktiv}>
                <Image
                  src="https://res.cloudinary.com/yneno/image/upload/v1743621415/about-page/verdier/proaktiv_j9xbwv.png"
                  alt={"Proaktiv"}
                />
                <h4>Proaktiv</h4>
              </div>
              <div className={styles.nysgjerrig}>
                <Image
                  src="https://res.cloudinary.com/yneno/image/upload/v1743621416/about-page/verdier/nysgjerrig_p7abkj.png"
                  alt={"Nysgjerrig"}
                />
                <h4>Nysgjerrig</h4>
              </div>
              <div className={styles.aerlig}>
                <Image
                  src="https://res.cloudinary.com/yneno/image/upload/v1743621416/about-page/verdier/aerlig_chqgzr.png"
                  alt={"Ærlig"}
                />
                <h4>Ærlig</h4>
              </div>
            </div>
          </section>

          <div style={{ backgroundColor: "#e6f4ed" }}>
            <section className="section-container">
              <div className={styles.summary}>
                <div>
                  <h1 className={styles.title}>Av utviklere, for utviklere</h1>
                  <p>
                    I Yne er vi gode på mye, men vi vil være best på
                    systemutvikling. Hos oss får du erfarne utviklere som kan
                    håndtere komplekse oppgaver med stor trygghet, solid
                    fagkompetanse og en fremoverlent holdning.
                  </p>
                </div>
                <div className={styles.keyInfo}>
                  <div>
                    <h3>32 år</h3>
                    <p>Snittalder</p>
                  </div>
                  <div>
                    <h3>2021</h3>
                    <p>etablert</p>
                  </div>
                  <div>
                    <h3>55%</h3>
                    <p>andel med mastergrad</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section
          className={`section-container ${styles.sectionPadding} ${styles["consultant-wrapper"]}`}
        >
          <h2>En Yne konsulent i oppdrag</h2>
          <div className={styles["consultant-image-text"]}>
            <div>
              <h3>Faglig trygg og nysgjerrig</h3>
              <p>
                Vi kombinerer solid kompetanse med en sult etter å lære mer. Vi
                stiller spørsmål, foreslår forbedringer og holder oss faglig
                oppdatert for å skape verdi for kundene.
              </p>
            </div>
            <Image
              src="https://res.cloudinary.com/yneno/image/upload/v1743670194/about-page/consultants/DSC02175_middels_xqvmjb.jpg"
              alt={"Trygg og nysgjerrig"}
              style={{
                borderRadius: "3vmin 8vmin 8vmin 10vmin",
                boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
              }}
            />
          </div>

          <div className={styles["consultant-image-text"]}>
            <Image
              src="https://res.cloudinary.com/yneno/image/upload/v1743670197/about-page/consultants/IMG_3666_middels_va0vdc.jpg"
              alt={"Tar eierskap og ansvar"}
              style={{
                borderRadius: "50% 20% 30% 70% / 40% 30% 70% 60%",
                transform: "rotate(-1deg)",
              }}
            />
            <div>
              <h3>Tar eierskap og ansvar</h3>
              <p>
                Vi bryr oss om det vi jobber med. Vi tar initiativ, følger opp,
                og sørger for at vi leverer med kvalitet – som om prosjektet var
                vårt eget.
              </p>
            </div>
          </div>

          <div className={styles["consultant-image-text"]}>
            <div>
              <h3>God til å samarbeide</h3>
              <p>
                Vi spiller kundens team gode. Vi lytter, involverer oss og
                bidrar aktivt til et godt samarbeidsklima – både faglig og
                sosialt.
              </p>
            </div>
            <Image
              src="https://res.cloudinary.com/yneno/image/upload/v1743665286/about-page/IMG_3264_zy5s6f.jpg"
              alt={"God til å samarbeide"}
              style={{
                borderRadius: "20% 10% 30% 10%",
                boxShadow: "0 6px 15px rgba(0, 0, 0, 0.08)",
              }}
            />
          </div>

          <div className={styles["consultant-image-text"]}>
            <Image
              src="https://res.cloudinary.com/yneno/image/upload/v1743663456/about-page/DSC02219_yanche.jpg"
              alt={"Tør å utfordre – på en konstruktiv måte"}
              style={{
                borderRadius: "5vmin 15vmin 2vmin 8vmin",
                transform: "rotate(1.5deg)",
              }}
            />
            <div>
              <h3>Tør å utfordre – på en konstruktiv måte</h3>
              <p>
                Vi sier ifra når noe kan gjøres bedre, og vi gjør det med
                respekt. Vi bygger tillit ved å være ærlige, tydelige og
                løsningsorienterte.
              </p>
            </div>
          </div>

          <div className={styles["consultant-image-text"]}>
            <div>
              <h3>Fleksibel og tilpasningsdyktig</h3>
              <p>
                Vi leser rommet, forstår konteksten og tilpasser oss kulturen og
                behovene i hvert enkelt prosjekt – uten å miste vår egen
                identitet.
              </p>
            </div>
            <Image
              src="https://res.cloudinary.com/yneno/image/upload/v1743670196/about-page/consultants/DSC02324_middels_zsikcu.jpg"
              alt={"Fleksibel og tilpasningsdyktig"}
              style={{
                borderRadius: "60% 40% 30% 70% / 60% 30% 70% 40%",
                transition: "transform 0.3s ease",
              }}
            />
          </div>

          <div className={styles["consultant-image-text"]}>
            <Image
              src="https://res.cloudinary.com/yneno/image/upload/v1743670194/about-page/consultants/DSC02171_middels_lcihxo.jpg"
              alt={"Representerer Yne med stolthet"}
              style={{
                borderRadius: "12px",
                boxShadow: "0 10px 25px rgba(0, 0, 0, 0.15)",
                transform: "rotate(-2deg)",
              }}
            />
            <div>
              <h3>Representerer Yne med stolthet</h3>
              <p>
                Vi er bevisste på at vi er ambassadører for Yne – i måten vi
                opptrer, kommuniserer og jobber på. Vi bygger merkevaren vår
                hver dag.
              </p>
            </div>
          </div>

          <div className={styles["consultant-image-text"]}>
            <div>
              <h3>Bidrar til framdrift og flyt</h3>
              <p>
                Vi skaper fremdrift og hjelper teamet med å holde tempo og
                retning. Vi gjør det enkelt å jobbe sammen med oss.
              </p>
            </div>
            <Image
              src="https://res.cloudinary.com/yneno/image/upload/v1743670194/about-page/consultants/DSC02167_middels_opfzec.jpg"
              alt={"Bidrar til framdrift og flyt"}
              style={{
                borderRadius: "0 4vmin 4vmin 4vmin",
                filter: "brightness(1.05)",
              }}
            />
          </div>

          <div className={styles["consultant-image-text"]}>
            <Image
              src="https://res.cloudinary.com/yneno/image/upload/v1743670198/about-page/consultants/IMG_6329_middels_bm1yvg.jpg"
              alt={"Stiller spørsmål, ikke bare leverer"}
              style={{
                borderRadius: "30% 50% 20% 40%",
                transform: "rotate(1deg)",
                boxShadow: "0 4px 12px rgba(0,0,0,0.12)",
              }}
            />
            <div>
              <h3>Stiller spørsmål, ikke bare leverer</h3>
              <p>
                Vi er ikke bare ressurs, vi er rådgiver. Vi utfordrer krav,
                stiller spørsmål og leter etter den beste løsningen – ikke bare
                den første.
              </p>
            </div>
          </div>
        </section>

        {/* <div
          style={{ backgroundColor: "#f2fcfd" }}
          className={styles.sectionPadding}
        >
          <section id="folk" className={`section-container`}>
            <p
              style={{
                maxWidth: 750,
                margin: "auto",
                textAlign: "center",
              }}
            >
              Vi leverer verdi til kunden gjennom engasjerte ansatte med faglig
              interesse og dybde. Derfor investerer vi i våre ansatte via
              konferanser, kurs og sosiale sammenkomster kombinert med faglig
              innhold. Her kan du lese litt om våre dyktige konsulenter.
            </p>
            <div style={{ margin: "4rem" }} className={styles.separator}>
              <div />
              <div />
            </div>
            <h2>Hvem er vi?</h2>
            <EmployeeGallery />
          </section>
        </div> */}

        <div
          className={styles.sectionPadding}
          style={{ backgroundColor: "#F4F4F4" }}
        >
          <section
            className="section-container"
            style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          >
            <h2>Prosjekter</h2>
            <h3>Kundereferanse: Axo Finans</h3>
            <YoutubeEmbed embedId="EiaLQ27G9OY" />
            <div className={styles["project-list"]}>
              <h3>Noen av våre prosjekter</h3>

              <ProjectList
                columns={3}
                limit={3}
                sortFn={() => 0.5 - Math.random()}
                headerAs={"h4"}
              />
            </div>

            <Link to={"/prosjekter"} className={styles.projects}>
              <PrimaryButton size="large" onClick={() => null}>
                {"Flere prosjekter"}
              </PrimaryButton>
            </Link>
          </section>
        </div>

        <div className={styles.sectionPadding}>
          <section
            className="section-container"
            style={{ paddingBottom: "5rem" }}
          >
            <h2>Siste fra bloggen</h2>
            <BlogFeed />
          </section>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
