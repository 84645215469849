import React, { FC } from "react";
import styles from "./buttons.module.scss";
import { Props } from "../../interfaces/button.interfaces";

const BasicButton: FC<Props> = ({ children, onClick, disabled }) => (
  <button
    onClick={onClick}
    className={styles.basicButton + (disabled ? " " + styles.disabled : "")}
    type="button"
    disabled={disabled}
  >
    {children}
  </button>
);

export default BasicButton;
