import sanityClient from "../helpers/client";

/**
 * Interface for event data returned from Sanity
 */
interface Event {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  date: string;
  time: string;
  startTime?: string;
  endTime?: string;
  starttime?: string;
  endtime?: string;
  description: string;
  fullDescription: any;
  location: string;
  signupLink?: string;
  eventType?: string;
  price?: string;
  contacts?: ContactPerson[];
  image?: {
    asset?: {
      _id?: string;
      url?: string;
    };
    alt?: string;
  };
  tags?: SanityReference[];
  targetAudience?: SanityReference[];
  featured?: boolean;
  speakers?: Speaker[];
  agenda?: any[];
}

/**
 * Interface for contact person data
 */
interface ContactPerson {
  _id: string;
  name: string;
  email?: string;
  phone?: string;
  title?: string;
  image?: {
    asset?: {
      _id?: string;
      url?: string;
    };
    alt?: string;
  };
}

/**
 * Interface for speaker data
 */
interface Speaker {
  _id: string;
  name: string;
  title?: string;
  bio?: any;
  image?: {
    asset?: {
      _id?: string;
      url?: string;
    };
    alt?: string;
  };
}

/**
 * Interface for Sanity reference types
 */
interface SanityReference {
  _id: string;
  name: string;
}

/**
 * Interface for related event data returned from Sanity
 */
interface RelatedEvent {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  date: string;
  time?: string;
  location?: string;
  image?: {
    asset?: {
      _id?: string;
      url?: string;
    };
    alt?: string;
  };
}

// Define the GROQ query field projections for reuse
const EVENT_FIELDS = `
  _id,
  title,
  slug,
  date,
  time,
  startTime,
  endTime,
  description,
  location,
  signupLink,
  eventType,
  price,
  image {
    asset->{
      _id,
      url
    },
    alt
  }
`;

const DETAILED_EVENT_FIELDS = `
  ${EVENT_FIELDS},
  fullDescription,
  contacts[]->{
    _id,
    name,
    email,
    phone,
    title,
    image {
      asset->{
        _id,
        url
      },
      alt
    }
  },
  tags[]->{
    _id,
    name
  },
  targetAudience[]->{
    _id,
    name
  },
  speakers[]->{
    _id,
    name,
    title,
    bio,
    image {
      asset->{
        _id,
        url
      },
      alt
    }
  },
  agenda
`;

const RELATED_EVENT_FIELDS = `
  _id,
  title,
  slug,
  date,
  time,
  location,
  image {
    asset->{
      _id,
      url
    },
    alt
  }
`;

/**
 * Formats a date from Sanity to a localized Norwegian date string
 * @param dateString The date string to format
 * @returns A formatted date string
 */
export function formatDate(dateString: string | undefined | null): string {
  if (!dateString) return "Dato ikke satt";

  try {
    const dateObj = new Date(dateString);

    if (isNaN(dateObj.getTime())) {
      console.warn(`Invalid date format: ${dateString}`);
      return "Dato ikke satt";
    }

    return dateObj.toLocaleDateString("nb-NO", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  } catch (err) {
    console.error(`Error formatting date: ${dateString}`, err);
    return "Dato ikke satt";
  }
}

/**
 * Fetches a single event by its slug
 * @param slug The event slug to fetch
 * @returns Promise resolving to the raw event data
 */
export function fetchEventBySlug(slug: string) {
  return sanityClient.fetch(
    `*[_type == "event" && slug.current == $slug][0] {
      ${DETAILED_EVENT_FIELDS}
    }`,
    { slug }
  );
}

/**
 * Fetches related events (upcoming events) excluding the specified event
 * @param currentEventSlug The slug of the current event to exclude
 * @param limit Maximum number of events to return
 * @returns Promise resolving to an array of related events
 */
export function fetchRelatedEvents(currentEventSlug: string, limit = 3) {
  return sanityClient.fetch(
    `*[_type == "event" && slug.current != $currentEventSlug && defined(date) && date >= now()][0...${limit}] {
      ${RELATED_EVENT_FIELDS}
    } | order(date asc)`,
    { currentEventSlug }
  );
}

/**
 * Fetches all upcoming events
 * @returns Promise resolving to an array of upcoming events
 */
export function fetchUpcomingEvents() {
  return sanityClient.fetch(
    `*[_type == "event" && defined(date) && date >= now()] | order(date asc) {
      ${EVENT_FIELDS},
      contacts[]->{
        _id,
        name,
        email,
        phone,
        title,
        image {
          asset->{
            _id,
            url
          },
          alt
        }
      },
      tags[]->{
        _id,
        name
      },
      targetAudience[]->{
        _id,
        name
      }
    }`
  );
}

/**
 * Fetches all upcoming events filtered by event type
 * @param eventType The type of events to fetch (e.g. "tekFyret" or "yneHub")
 * @returns Promise resolving to an array of upcoming events of the specified type
 */
export function fetchUpcomingEventsByType(eventType: string) {
  return sanityClient.fetch(
    `*[_type == "event" && defined(date) && date >= now() && eventType == $eventType] | order(date asc) {
      ${EVENT_FIELDS},
      contacts[]->{
        _id,
        name,
        email,
        phone,
        title,
        image {
          asset->{
            _id,
            url
          },
          alt
        }
      },
      tags[]->{
        _id,
        name
      },
      targetAudience[]->{
        _id,
        name
      }
    }`,
    { eventType }
  );
}

export type { Event, RelatedEvent, ContactPerson, Speaker, SanityReference };
