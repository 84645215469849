import { useQuery } from "@tanstack/react-query";
import {
  fetchEventBySlug,
  fetchRelatedEvents,
  fetchUpcomingEvents,
  fetchUpcomingEventsByType,
  formatDate,
  Event,
  RelatedEvent,
} from "../queries/eventQueries";
import {
  createShareUrls,
  getCurrentUrl,
} from "../pages/event/utils/shareUtils";
import { createCalendarEvent } from "../pages/event/utils/eventCalendarUtils";

/**
 * Hook to fetch a single event by slug
 * @param slug The event slug to fetch
 * @returns Query result with the event data
 */
export function useEvent(slug: string | undefined) {
  return useQuery({
    queryKey: ["event", slug],
    queryFn: () => fetchEventBySlug(slug!),
    enabled: !!slug,
    select: (data: Event | null) => {
      if (!data) return null;

      return {
        ...data,
        date: formatDate(data.date),
        // Ensure these are arrays even if they come back as null
        tags: data.tags || [],
        targetAudience: data.targetAudience || [],
        speakers: data.speakers || [],
        agenda: data.agenda || [],
        contacts: data.contacts || [],
        // Make sure the price field is preserved
        price: data.price || "",
      };
    },
  });
}

/**
 * Hook to fetch related events
 * @param currentEventSlug The slug of the current event to exclude
 * @param limit Maximum number of events to return
 * @returns Query result with related events data
 */
export function useRelatedEvents(
  currentEventSlug: string | undefined,
  limit = 3
) {
  return useQuery({
    queryKey: ["relatedEvents", currentEventSlug, limit],
    queryFn: () => fetchRelatedEvents(currentEventSlug!, limit),
    enabled: !!currentEventSlug,
    select: (data: RelatedEvent[]) => {
      return data
        .filter((event) => !!event) // Filter out any null events
        .map((event) => ({
          ...event,
          date: formatDate(event.date),
        }));
    },
  });
}

/**
 * Hook to fetch all upcoming events
 * @returns Query result with upcoming events data
 */
export function useUpcomingEvents() {
  return useQuery({
    queryKey: ["upcomingEvents"],
    queryFn: fetchUpcomingEvents,
    select: (data: Event[]) => {
      return data.map((event) => ({
        ...event,
        date: formatDate(event.date),
        contacts: event.contacts || [],
        tags: event.tags || [],
        targetAudience: event.targetAudience || [],
        price: event.price || "",
      }));
    },
  });
}

/**
 * Hook to fetch upcoming events filtered by event type
 * @param eventType The type of events to fetch (e.g. "tekFyret" or "yneHub")
 * @returns Query result with upcoming events data of the specified type
 */
export function useUpcomingEventsByType(eventType: string) {
  return useQuery({
    queryKey: ["upcomingEvents", eventType],
    queryFn: () => fetchUpcomingEventsByType(eventType),
    select: (data: Event[]) => {
      return data.map((event) => ({
        ...event,
        date: formatDate(event.date),
        contacts: event.contacts || [],
        tags: event.tags || [],
        targetAudience: event.targetAudience || [],
        price: event.price || "",
      }));
    },
  });
}

/**
 * Combined hook for event details page that provides formatted event data
 * and related utilities like share URLs and calendar event
 * @param eventSlug The slug of the event to fetch
 * @returns Formatted event data and related utilities
 */
export function useEventDetails(eventSlug: string | undefined) {
  const {
    data: event,
    isLoading: isEventLoading,
    error: eventError,
  } = useEvent(eventSlug);

  const { data: relatedEvents = [], isLoading: isRelatedLoading } =
    useRelatedEvents(eventSlug);

  // Create share URLs if we have an event
  const shareUrls = event
    ? createShareUrls(event, getCurrentUrl(eventSlug!))
    : null;

  // Create calendar event if we have an event
  const calendarEvent = event ? createCalendarEvent(event) : null;

  // Calculate display date and time
  let displayDate = "Dato ikke satt";
  let displayTime = "Tid ikke satt";

  if (event) {
    displayDate = event.date || "Dato ikke satt";

    if (event.startTime && event.endTime) {
      try {
        // Format startTime and endTime to show only the time portion
        const startDate = new Date(event.startTime);
        const endDate = new Date(event.endTime);

        if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
          const formattedStartTime = startDate.toLocaleTimeString("nb-NO", {
            hour: "2-digit",
            minute: "2-digit",
          });
          const formattedEndTime = endDate.toLocaleTimeString("nb-NO", {
            hour: "2-digit",
            minute: "2-digit",
          });
          displayTime = `${formattedStartTime} - ${formattedEndTime}`;
        } else {
          // Fallback to original strings if dates are invalid
          displayTime = `${event.startTime} - ${event.endTime}`;
          console.warn("Invalid date format in event times:", {
            startTime: event.startTime,
            endTime: event.endTime,
          });
        }
      } catch (err) {
        // Error handling in case of date parsing errors
        console.error("Error formatting event times:", err);
        displayTime = `${event.startTime} - ${event.endTime}`;
      }
    } else if (event.time) {
      displayTime = event.time;
    }
  }

  const isLoading = isEventLoading || isRelatedLoading;

  return {
    event,
    relatedEvents,
    isLoading,
    error: eventError,
    shareUrls,
    calendarEvent,
    displayDate,
    displayTime,
  };
}
