import React from "react";
import styles from "./tekfyret.module.css";
import lighthouseImg from "../../assets/images/lighthouse.png";
import {
  faLightbulb,
  faHandshake,
  faChalkboardTeacher,
  faBridge,
} from "@fortawesome/free-solid-svg-icons";
import FeatureCard from "../../components/FeatureCard";
import FeaturedEvent from "../../components/FeaturedEvent";
import LightHouseHeader from "../../components/lighthouseHeader";
import EventCard from "../../components/eventCard/EventCard";
import Button from "../../components/Button";
import { Event } from "../../queries/eventQueries";
import { useUpcomingEventsByType } from "../../hooks/useEventQueries";
import { Helmet } from "react-helmet-async";

/**
 * Formats event time based on startTime and endTime fields
 * @param event Event object containing time data
 * @returns Formatted time string
 */
const formatEventTime = (event: Event) => {
  let displayTime = event.time || "";

  // If we have startTime, use a proper localized format
  if (event.startTime) {
    // Simple time display format
    if (event.endTime) {
      displayTime = `${new Date(event.startTime).toLocaleTimeString("nb-NO", {
        hour: "2-digit",
        minute: "2-digit",
      })} - ${new Date(event.endTime).toLocaleTimeString("nb-NO", {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    } else {
      displayTime = new Date(event.startTime).toLocaleTimeString("nb-NO", {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }

  return displayTime;
};

const TekfyretPage: React.FC = () => {
  // Use React Query hook to fetch upcoming events filtered by type "tekFyret"
  const {
    data: events = [],
    isLoading,
    error,
  } = useUpcomingEventsByType("tekFyret");

  // Since featured is not being returned in this simpler query, let's just use the first event
  const featuredEvent = events.length > 0 ? events[0] : null;

  if (isLoading) {
    return <div className={styles.loading}>Loading events...</div>;
  }

  if (error) {
    return <div className={styles.error}>{String(error)}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Tekfyret | Yne Consulting</title>
        <meta
          name="description"
          content="Tekfyret arrangerer events innen teknologi, utvikling og digital innovasjon. Bli med på våre kommende arrangementer!"
        />
      </Helmet>

      <LightHouseHeader
        title={
          <>
            <span className={styles.tekPart}>Tek</span>
            <span className={styles.fyretPart}>Fyret</span>
          </>
        }
        subtitle="Et fyrtårn innen teknologi og innovasjon"
        backgroundImage={lighthouseImg}
        variant="parallax"
      />

      <div className={styles.container}>
        <section className={styles.intro}>
          <div className={styles.introContent}>
            <h2 className={styles.animateTitle}>Velkommen til Tekfyret</h2>

            <div className={styles.introFlexContainer}>
              <div className={styles.introImageContainer}>
                <img
                  src={lighthouseImg}
                  alt="Tekfyret lighthouse"
                  className={styles.introImage}
                />
              </div>

              <div className={styles.introTextContent}>
                <p className={styles.introParagraph}>
                  Hos Yne brenner vi for teknologi og faglig utvikling. Gjennom
                  våre Tekfyret-arrangementer deler vi kunnskap, bygger nettverk
                  og utforsker nye teknologier sammen. Som et fyrtårn i
                  teknologihavet, lyser vi opp veien til innovasjon og
                  kompetanseheving.
                </p>

                <div className={styles.introDescription}>
                  <p>
                    TekFyret er en faglig møteplass{" "}
                    <strong>skapt av utviklere – for utviklere</strong> – men
                    med en klar bro til dem som trenger teknologien mest. Vi
                    kombinerer dyp teknisk kompetanse med evnen til å
                    kommunisere, tilpasse og anvende teknologi på en måte som
                    gir konkret verdi i arbeidslivet.
                  </p>
                  <p>
                    Hos oss møtes teknologi og praksis. Vi snakker ikke bare om
                    kode og verktøy, men også om hvordan teknologi faktisk
                    brukes, påvirker og forbedrer arbeidshverdagen – på tvers av
                    bransjer og roller.
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.featureGrid}>
              <FeatureCard
                icon={faLightbulb}
                title="Innovasjon"
                description="Vi utforsker nye teknologier og løsninger som gir reell verdi og skaper positive endringer i arbeidslivet."
              />

              <FeatureCard
                icon={faHandshake}
                title="Faglig Fellesskap"
                description="En møteplass for teknologientusiaster hvor vi deler kunnskap og bygger nettverk på tvers av roller og industrier."
              />

              <FeatureCard
                icon={faChalkboardTeacher}
                title="Kompetanseheving"
                description="Vi kombinerer dyp teknisk kompetanse med evnen til å kommunisere og anvende teknologi på en praktisk måte."
              />

              <FeatureCard
                icon={faBridge}
                title="Brobygging"
                description="Vi bygger bro mellom utviklere og beslutningstakere for å skape en felles forståelse for teknologiens potensiale."
              />
            </div>
          </div>
        </section>

        {/* Only show featured event if it has the necessary data with image */}
        {featuredEvent &&
          featuredEvent.slug &&
          featuredEvent.image &&
          featuredEvent.image.asset &&
          featuredEvent.image.asset.url && (
            <section className={styles.featuredEventSection}>
              <FeaturedEvent
                id={featuredEvent.slug.current || ""}
                title={featuredEvent.title || ""}
                date={featuredEvent.date || ""}
                description={featuredEvent.description || ""}
                image={featuredEvent.image.asset.url}
                tags={featuredEvent.tags?.map((tag) => tag.name) || []}
                targetAudience={
                  featuredEvent.targetAudience?.map(
                    (audience) => audience.name
                  ) || []
                }
                time={formatEventTime(featuredEvent)}
              />
            </section>
          )}

        <section className={styles.eventsSection}>
          <h2 className={styles.sectionTitle}>Kommende arrangementer</h2>
          {events.length === 0 ? (
            <p className={styles.noEvents}>
              Ingen kommende arrangementer for øyeblikket.
            </p>
          ) : (
            <div className={styles.eventsGrid}>
              {events
                .filter(
                  (event) =>
                    event.image && event.image.asset && event.image.asset.url
                )
                .map((event) => (
                  <EventCard
                    key={event._id}
                    id={event.slug?.current || ""}
                    title={event.title || ""}
                    date={event.date || ""}
                    time={formatEventTime(event)}
                    description={event.description || ""}
                    image={event.image!.asset!.url!}
                    tags={event.tags?.map((tag) => tag.name) || []}
                    targetAudience={
                      event.targetAudience?.map((audience) => audience.name) ||
                      []
                    }
                  />
                ))}
            </div>
          )}
        </section>

        <section className={styles.ctaSection}>
          <div className={styles.ctaContent}>
            <h2>Har du forslag til arrangementer?</h2>
            <p>
              Vi ønsker alltid å høre fra deg! Har du ideer til faglige
              arrangementer eller ønsker å holde en presentasjon hos oss? Vi er
              alltid på utkikk etter spennende temaer og engasjerte talere.
            </p>
            <Button color="summer-green" href="/kontakt-oss" size="large">
              Ta kontakt
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};

export default TekfyretPage;
