import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./pages/about-us";
import Blog from "./pages/blog";
import ContactUs from "./pages/contact-us";
import Home from "./pages/home";
import InternalPage from "./pages/internal-page";
import JobPage from "./pages/job";
import JobInfo from "./pages/job-info";
import NotFoundPage from "./pages/not-found-page";
import PersonnelHandbook from "./pages/personnel-handbook";
import Post from "./pages/post";
import { Projects } from "./pages/projects";
import { Project } from "./pages/projects/project";
import SalaryCalculator from "./pages/salary-calculator";
import Terms from "./pages/terms";
import OldPersonnelHandbook from "./pages/personnel-handbook/old-personnel-handbook";
import ServiceDetails from "./pages/service-details";
import Frilans from "./pages/frilans";
import { ServicePage } from "./pages/service-page/ServicePage";
import OfficePage from "./pages/office";
import TekfyretPage from "./pages/tekfyret/index";
import EventPage from "./pages/event/EventDetail";
import YneHubPage from "./pages/yneHub";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/jobb" element={<JobPage />} />
      <Route path="/frilans" element={<Frilans />} />
      <Route path="/om-oss" element={<AboutUs />} />
      <Route path="/blogg" element={<Blog />} />
      <Route path="/blogg/:slug" element={<Post />} />
      <Route path="/kontakt-oss" element={<ContactUs />} />
      <Route path="/jobb/:slug" element={<JobInfo />} />
      <Route path="/ny-personalhandbok" element={<PersonnelHandbook />} />
      <Route path="/personalhandbok" element={<PersonnelHandbook />} />
      <Route path="/personalhandbok-old" element={<OldPersonnelHandbook />} />
      <Route path="/personvern" element={<Terms />} />
      <Route path="/intern/*" element={<InternalPage />} />
      <Route path="/lonnskalkulator" element={<SalaryCalculator />} />
      <Route path="/prosjekter" element={<Projects />} />
      <Route path="/prosjekter/:slug" element={<Project />} />
      <Route path="/tjenester/" element={<ServicePage />} />
      <Route path="/tjenester/:name" element={<ServiceDetails />} />
      <Route path="/kontor" element={<OfficePage />} />
      <Route path="/tekfyret" element={<TekfyretPage />} />
      <Route path="/ynehub" element={<YneHubPage />} />
      <Route path="/event/:eventId" element={<EventPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
