import React from "react";
import { Link } from "react-router-dom";
import styles from "./Button.module.css";

export interface ButtonProps {
  /**
   * Button text content
   */
  children: React.ReactNode;
  /**
   * Optional CSS class name to override styles
   */
  className?: string;
  /**
   * Button color variant
   */
  color?:
    | "primary"
    | "secondary"
    | "summer-green"
    | "norway-sea"
    | "white"
    | "gray";
  /**
   * Button size variant
   */
  size?: "small" | "medium" | "large";
  /**
   * Button variant style
   */
  variant?: "solid" | "outline" | "text";
  /**
   * Optional icon to display before the text
   */
  icon?: React.ReactNode;
  /**
   * Optional href for anchor tag rendering
   */
  href?: string;
  /**
   * Optional to attribute for anchor tag
   */
  target?: "_blank" | "_self" | "_parent" | "_top";
  /**
   * Optional to attribute for anchor tag
   */
  rel?: string;
  /**
   * Optional Link path (uses React Router's Link component)
   */
  to?: string;
  /**
   * Button type (for form submissions)
   */
  type?: "button" | "submit" | "reset";
  /**
   * Click handler
   */
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  /**
   * Disabled state
   */
  disabled?: boolean;
}

/**
 * Reusable Button component with multiple variants and color options
 */
const Button: React.FC<ButtonProps> = ({
  children,
  className = "",
  color = "primary",
  size = "medium",
  variant = "solid",
  icon,
  href,
  target,
  rel,
  to,
  type = "button",
  onClick,
  disabled = false,
}) => {
  const buttonClasses = `
    ${styles.button}
    ${styles[`color-${color}`]}
    ${styles[`size-${size}`]}
    ${styles[`variant-${variant}`]}
    ${disabled ? styles.disabled : ""}
    ${className}
  `;

  // Content with optional icon
  const content = (
    <>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.text}>{children}</span>
    </>
  );

  // Render as Link component
  if (to) {
    return (
      <Link to={to} className={buttonClasses} onClick={onClick as any}>
        {content}
      </Link>
    );
  }

  // Render as anchor tag
  if (href) {
    return (
      <a
        href={href}
        className={buttonClasses}
        target={target}
        rel={rel || (target === "_blank" ? "noopener noreferrer" : undefined)}
        onClick={onClick as any}
      >
        {content}
      </a>
    );
  }

  // Render as button
  return (
    <button
      type={type}
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export default Button;
