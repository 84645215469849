import React, { useEffect, useRef } from "react";
import styles from "./YneHubHeader.module.css";

interface YneHubHeaderProps {
  title: React.ReactNode;
  subtitle: string;
  backgroundImage?: string;
}

const YneHubHeader: React.FC<YneHubHeaderProps> = ({
  title,
  subtitle,
  backgroundImage,
}) => {
  const headerRef = useRef<HTMLElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    // Animate title on load with subtle fade in
    if (titleRef.current) {
      titleRef.current.classList.add(styles.visible);
    }
  }, []);

  return (
    <header ref={headerRef} className={styles.header}>
      {/* Background image with reduced opacity */}
      {backgroundImage && (
        <div
          className={styles.backgroundImage}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
      )}

      {/* Color overlay */}
      <div className={styles.overlay}></div>

      {/* Horizontal accent bar */}
      <div className={styles.accentBar}></div>

      {/* Header content */}
      <div className={styles.content}>
        <h1 ref={titleRef} className={styles.title}>
          {title}
        </h1>
        <div className={styles.subtitleContainer}>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
      </div>

      {/* Minimal decorative corner elements */}
      <div className={styles.cornerTopRight}></div>
      <div className={styles.cornerBottomLeft}></div>
    </header>
  );
};

export default YneHubHeader;
