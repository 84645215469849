import React from "react";

import Image from "../image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import Tag from "../Tag";
import Button from "../Button";
import styles from "./FeaturedEvent.module.css";

interface FeaturedEventProps {
  id: string;
  title: string;
  date: string;
  description: string;
  image: string;
  tags: string[];
  targetAudience?: string[];
  location?: string;
  time?: string;
  className?: string;
}

const FeaturedEvent: React.FC<FeaturedEventProps> = ({
  id,
  title,
  date,
  description,
  image,
  tags,
  targetAudience = [],
  time,
  className = "",
}) => {
  return (
    <div className={`${styles.featuredContent} ${className}`}>
      <div className={styles.featuredInfo}>
        <div className={styles.featuredInfoWrapper}>
          <h2>Neste arrangement</h2>
          <h3>{title}</h3>
          <div className={styles.eventMetaContainer}>
            <span className={styles.eventMeta}>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className={styles.eventIcon}
              />{" "}
              {date}
            </span>
            {time && (
              <span className={styles.eventMeta}>
                <FontAwesomeIcon icon={faClock} className={styles.eventIcon} />{" "}
                {time}
              </span>
            )}
          </div>
          <p className={styles.featuredDescription}>{description}</p>
          <div className={styles.featuredTags}>
            {tags.map((tag, index) => (
              <Tag key={`tag-${index}`} variant="large">
                {tag}
              </Tag>
            ))}
            {targetAudience.map((audience, index) => (
              <Tag key={`audience-${index}`} variant="large" color="turquoise">
                {audience}
              </Tag>
            ))}
          </div>
          <p>
            <Button to={`/event/${id}`} color="summer-green" size="large">
              Les mer og meld deg på
            </Button>
          </p>
        </div>
      </div>
      <div className={styles.featuredImageContainer}>
        <Image src={image} alt={title} className={styles.featuredImage} />
      </div>
    </div>
  );
};

export default FeaturedEvent;
