import React from "react";
import { Link } from "react-router-dom";
import Image from "../image";
import styles from "./SidebarEventCard.module.css";

interface SidebarEventCardProps {
  id: string;
  title: string;
  date: string;
  image: string;
  linkPrefix?: string;
}

const SidebarEventCard: React.FC<SidebarEventCardProps> = ({
  id,
  title,
  date,
  image,
  linkPrefix = "/event",
}) => {
  return (
    <div className={styles.upcomingEvent}>
      <div className={styles.upcomingEventImage}>
        <Image src={image} alt={title} className={styles.upcomingEventImg} />
      </div>
      <div className={styles.upcomingEventInfo}>
        <h4>{title}</h4>
        <p>{date}</p>
        <Link to={`${linkPrefix}/${id}`} className={styles.viewEventLink}>
          Se arrangement
        </Link>
      </div>
    </div>
  );
};

export default SidebarEventCard;
