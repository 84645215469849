import { useEffect, FC } from "react";
import { useLocation } from "react-router-dom";

const pathNameHistory = new Set<string>();

const ScrollToTop: FC = (): null => {
  const { pathname, hash } = useLocation();

  useEffect((): void => {
    if (!pathNameHistory.has(pathname) && !hash) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);
      pathNameHistory.add(pathname);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
