import React from "react";
import styles from "./EventSpeakers.module.css";
import SpeakerCard from "../../../../components/speakerCard/SpeakerCard";

interface Speaker {
  _id?: string;
  name?: string;
  title?: string;
  bio?: string;
  image?: {
    asset?: {
      _id?: string;
      url?: string;
    };
    alt?: string;
  };
}

interface EventSpeakersProps {
  speakers: Speaker[];
}

const EventSpeakers: React.FC<EventSpeakersProps> = ({ speakers }) => {
  if (!speakers || speakers.length === 0) {
    return null;
  }

  return (
    <section className={styles.speakers}>
      <h2>Foredragsholdere</h2>
      <div className={styles.speakersGrid}>
        {speakers
          .filter(
            (speaker) =>
              speaker != null &&
              speaker.image &&
              speaker.image.asset &&
              speaker.image.asset.url
          )
          .map((speaker, index) => (
            <SpeakerCard
              key={index}
              name={speaker.name || ""}
              title={speaker.title || ""}
              image={speaker.image!.asset!.url!}
              bio={speaker.bio || ""}
            />
          ))}
      </div>
    </section>
  );
};

export default EventSpeakers;
