import React from "react";
import styles from "./image.module.scss";

interface ImageProps {
  className?: string;
  src: string;
  alt?: string;
  style?: React.CSSProperties | undefined;
}

const Image = ({ className, src, alt, style }: ImageProps) => (
  <img
    className={`${styles.img} ${className}`}
    src={src}
    alt={alt}
    style={style}
  />
);

export default Image;
