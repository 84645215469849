import React from "react";
import styles from "../tekfyret/tekfyret.module.css";
import yneIllustration from "../../assets/images/yne-illustration.png";
import yneHubImage from "../../assets/images/ynehub.jpeg";
import {
  faCode,
  faUsers,
  faLaptopCode,
  faNetworkWired,
} from "@fortawesome/free-solid-svg-icons";
import FeatureCard from "../../components/FeatureCard";
import FeaturedEvent from "../../components/FeaturedEvent";
import EventCard from "../../components/eventCard/EventCard";
import Button from "../../components/Button";
import { Event } from "../../queries/eventQueries";
import { useUpcomingEvents } from "../../hooks/useEventQueries";
import { Helmet } from "react-helmet-async";
import YneHubHeader from "./YneHubHeader";

/**
 * Formats event time based on startTime and endTime fields
 * @param event Event object containing time data
 * @returns Formatted time string
 */
const formatEventTime = (event: Event) => {
  let displayTime = event.time || "";

  // If we have startTime, use a proper localized format
  if (event.startTime) {
    // Simple time display format
    if (event.endTime) {
      displayTime = `${new Date(event.startTime).toLocaleTimeString("nb-NO", {
        hour: "2-digit",
        minute: "2-digit",
      })} - ${new Date(event.endTime).toLocaleTimeString("nb-NO", {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    } else {
      displayTime = new Date(event.startTime).toLocaleTimeString("nb-NO", {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }

  return displayTime;
};

const YneHubPage: React.FC = () => {
  // Use React Query hook to fetch all upcoming events regardless of type
  // This makes YneHub a central hub showing all event types
  const { data: events = [], isLoading, error } = useUpcomingEvents();

  // Since featured is not being returned in this simpler query, let's just use the first event
  const featuredEvent = events.length > 0 ? events[0] : null;

  if (isLoading) {
    return <div className={styles.loading}>Loading events...</div>;
  }

  if (error) {
    return <div className={styles.error}>{String(error)}</div>;
  }

  return (
    <>
      <Helmet>
        <title>YneHub | Yne Consulting</title>
        <meta
          name="description"
          content="YneHub er en møteplass hvor alle typer mennesker kan møtes for alle typer arrangementer - fra tech til kunst, fra workshops til sosiale samlinger."
        />
      </Helmet>

      <YneHubHeader
        title={
          <>
            <span>Yne</span>
            <span style={{ color: "var(--color-summer-green)" }}>Hub</span>
          </>
        }
        subtitle="En møteplass for alle typer mennesker og arrangementer"
        backgroundImage={yneHubImage}
      />

      <div className={styles.container}>
        <section className={styles.intro}>
          <div className={styles.introContent}>
            <h2 className={styles.animateTitle}>Velkommen til YneHub</h2>

            <div className={styles.introFlexContainer}>
              <div className={styles.introImageContainer}>
                <img
                  src={yneIllustration}
                  alt="YneHub illustration"
                  className={styles.introImage}
                />
              </div>

              <div className={styles.introTextContent}>
                <p className={styles.introParagraph}>
                  YneHub er et inkluderende fellesskap hvor mennesker med ulike
                  interesser og bakgrunner kan møtes for alle typer
                  arrangementer og samlinger. Vi skaper et rom der ulike
                  verdener kan krysse hverandre og nye nettverk kan bygges.
                </p>

                <div className={styles.introDescription}>
                  <p>
                    På YneHub finner du en arena hvor{" "}
                    <strong>mennesker møtes på tvers av interesser</strong>. Vi
                    tilbyr et mangfoldig rom for alt fra teknologiske meetups
                    til kunstutstillinger, fra workshops til sosiale
                    sammenkomster.
                  </p>
                  <p>
                    Enten du er interessert i tech, kunst, kultur, eller bare
                    ønsker et sted å møte likesinnede, er YneHub et fellesskap
                    hvor alle er velkomne. Vi er en møteplass som bringer
                    mennesker sammen for å dele ideer, skape nye forbindelser og
                    bygge fellesskap.
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.featureGrid}>
              <FeatureCard
                icon={faCode}
                title="Mangfoldige Arrangementer"
                description="Fra tech meetups til kunstutstillinger, workshops til sosiale samlinger - YneHub er et hjem for alle typer aktiviteter."
              />

              <FeatureCard
                icon={faUsers}
                title="Fellesskap"
                description="Et inkluderende miljø hvor mennesker med ulike bakgrunner og interesser kan møtes, dele erfaringer og bygge nettverk."
              />

              <FeatureCard
                icon={faLaptopCode}
                title="Læring og Inspirasjon"
                description="Et rom for kunnskapsutvikling og kreativ utfoldelse på tvers av fagfelt og interesseområder."
              />

              <FeatureCard
                icon={faNetworkWired}
                title="Nettverksbygging"
                description="Skaper forbindelser mellom ulike miljøer og interessegrupper for å fremme samarbeid og nytenkning."
              />
            </div>
          </div>
        </section>

        {/* Only show featured event if it has the necessary data with image */}
        {featuredEvent &&
          featuredEvent.slug &&
          featuredEvent.image &&
          featuredEvent.image.asset &&
          featuredEvent.image.asset.url && (
            <section className={styles.featuredEventSection}>
              <FeaturedEvent
                id={featuredEvent.slug.current || ""}
                title={featuredEvent.title || ""}
                date={featuredEvent.date || ""}
                description={featuredEvent.description || ""}
                image={featuredEvent.image.asset.url}
                tags={featuredEvent.tags?.map((tag) => tag.name) || []}
                targetAudience={
                  featuredEvent.targetAudience?.map(
                    (audience) => audience.name
                  ) || []
                }
                time={formatEventTime(featuredEvent)}
              />
            </section>
          )}

        <section className={styles.eventsSection}>
          <h2 className={styles.sectionTitle}>Kommende arrangementer</h2>
          {events.length === 0 ? (
            <p className={styles.noEvents}>
              Ingen kommende arrangementer for øyeblikket.
            </p>
          ) : (
            <div className={styles.eventsGrid}>
              {events
                .filter(
                  (event) =>
                    event.image && event.image.asset && event.image.asset.url
                )
                .map((event) => (
                  <EventCard
                    key={event._id}
                    id={event.slug?.current || ""}
                    title={event.title || ""}
                    date={event.date || ""}
                    time={formatEventTime(event)}
                    description={event.description || ""}
                    image={event.image!.asset!.url!}
                    tags={event.tags?.map((tag) => tag.name) || []}
                    targetAudience={
                      event.targetAudience?.map((audience) => audience.name) ||
                      []
                    }
                  />
                ))}
            </div>
          )}
        </section>

        <section className={styles.ctaSection}>
          <div className={styles.ctaContent}>
            <h2>Har du forslag til YneHub?</h2>
            <p>
              Vi utvikler kontinuerlig YneHub for å møte behovene til alle som
              bruker dette fellesskapet. Har du ideer til aktiviteter,
              arrangementer eller forbedringer? Vi setter stor pris på innspill
              som kan gjøre møteplassen enda bedre for alle!
            </p>
            <Button color="summer-green" href="/kontakt-oss" size="large">
              Send innspill
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};

export default YneHubPage;
