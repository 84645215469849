import React from "react";
import styles from "./EventInfo.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClock,
  faMapMarkerAlt,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";

interface EventInfoProps {
  date: string;
  time: string;
  location: string;
  price?: string | number;
}

const EventInfo: React.FC<EventInfoProps> = ({
  date,
  time,
  location,
  price,
}) => {
  // Convert price to string explicitly before using string methods
  const priceString = price ? String(price) : "";
  const formattedPrice =
    priceString && !priceString.endsWith(",-")
      ? `${priceString},-`
      : priceString;

  return (
    <div className={styles.eventInfo}>
      <div className={styles.infoItem}>
        <FontAwesomeIcon icon={faCalendarAlt} />
        <span>{date}</span>
      </div>
      <div className={styles.infoItem}>
        <FontAwesomeIcon icon={faClock} />
        <span>{time}</span>
      </div>
      <div className={styles.infoItem}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <span>{location}</span>
      </div>
      {price && (
        <div className={styles.infoItem}>
          <FontAwesomeIcon icon={faCoins} />
          <span>{formattedPrice}</span>
        </div>
      )}
    </div>
  );
};
export default EventInfo;
