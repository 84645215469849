import React from "react";
import Image from "../image";
import styles from "./SpeakerCard.module.css";

interface SpeakerCardProps {
  name: string;
  title: string;
  image: string;
  bio: string;
}

const SpeakerCard: React.FC<SpeakerCardProps> = ({
  name,
  title,
  image,
  bio,
}) => {
  return (
    <div className={styles.speakerCard}>
      <div className={styles.speakerImageContainer}>
        <Image src={image} alt={name} className={styles.speakerImage} />
      </div>
      <div className={styles.speakerInfo}>
        <h3 className={styles.speakerName}>{name}</h3>
        <p className={styles.speakerTitle}>{title}</p>
        <p className={styles.speakerBio}>{bio}</p>
      </div>
    </div>
  );
};

export default SpeakerCard;
