import React from "react";
import styles from "./EventDescription.module.css";
import { BlockContent } from "../../../../helpers/sanity";
import EventInfo from "../eventInfo/EventInfo";
import EventCTA from "../eventCTA/EventCTA";

interface EventDescriptionProps {
  date: string;
  time: string;
  location: string;
  title: string;
  description: string;
  fullDescription: any;
  signupLink?: string;
  price?: string;
}

const EventDescription: React.FC<EventDescriptionProps> = ({
  date,
  time,
  location,
  title,
  fullDescription,
  signupLink,
  price,
}) => {
  return (
    <section className={styles.eventDescription}>
      <EventInfo date={date} time={time} location={location} price={price} />

      <div className={styles.descriptionContent}>
        <BlockContent blocks={fullDescription} />
      </div>

      <EventCTA title={title} signupLink={signupLink} />
    </section>
  );
};

export default EventDescription;
