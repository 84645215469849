import React, { useState } from "react";
import styles from "./event-details.module.css";
import { useParams } from "react-router-dom";
import MetaHeader from "../../components/metaheader";
import {
  EventHero,
  EventDescription,
  EventAgenda,
  EventSpeakers,
  EventSidebar,
} from "./components";
import StatusHandler from "./components/StatusHandler";
import { useEventDetails } from "../../hooks/useEventQueries";
import { getCurrentUrl } from "./utils/shareUtils";

// Define the types we need
interface AgendaItem {
  time?: string;
  title?: string;
}

interface Speaker {
  _id?: string;
  name?: string;
  title?: string;
  bio?: string;
  image?: {
    asset?: {
      _id?: string;
      url?: string;
    };
    alt?: string;
  };
}

const EventPage: React.FC = () => {
  const params = useParams<{ eventId: string }>();
  const eventSlug = params.eventId || "";
  const [linkCopied, setLinkCopied] = useState(false);

  // Use our React Query custom hook to handle data fetching and processing
  const {
    event,
    relatedEvents,
    isLoading,
    error,
    shareUrls,
    calendarEvent,
    displayDate,
    displayTime,
  } = useEventDetails(eventSlug);

  const handleCopyLink = () => {
    if (!shareUrls) return;

    navigator.clipboard
      .writeText(shareUrls.currentUrl)
      .then(() => {
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  // Check if we have a valid event and image
  const hasEvent = !!event;
  const hasImage = !!event?.image?.asset?.url;

  // Safely extract the background image URL
  let backgroundImageUrl = "";
  if (hasImage && event?.image?.asset?.url) {
    backgroundImageUrl = event.image.asset.url;
  }

  const currentUrl = getCurrentUrl(eventSlug);

  // Ensure we have properly typed arrays
  const agenda: AgendaItem[] = Array.isArray(event?.agenda)
    ? (event?.agenda as AgendaItem[])
    : [];
  const speakers: Speaker[] = Array.isArray(event?.speakers)
    ? (event?.speakers as Speaker[])
    : [];

  return (
    <StatusHandler
      isLoading={isLoading}
      error={error ? String(error) : null}
      hasImage={hasImage}
      hasEvent={hasEvent}
    >
      <MetaHeader
        description={event?.description || "Event hos Yne Consulting"}
        url={currentUrl}
        title={`${event?.title || "Event"} | Tekfyret | Yne`}
        socialMediaDescription={
          event?.description ||
          "Bli med på dette spennende arrangementet i regi av Yne Consulting"
        }
        imageUrl={backgroundImageUrl}
      />
      <div className={styles.container}>
        <EventHero
          title={event?.title || ""}
          backgroundImageUrl={backgroundImageUrl}
          tags={event?.tags && Array.isArray(event?.tags) ? event?.tags : []}
          targetAudience={
            event?.targetAudience && Array.isArray(event?.targetAudience)
              ? event?.targetAudience
              : []
          }
        />

        <div className={styles.content}>
          <div className={styles.mainContent}>
            <EventDescription
              date={displayDate}
              time={displayTime}
              location={event?.location || ""}
              title={event?.title || ""}
              description={event?.description || ""}
              fullDescription={event?.fullDescription || ""}
              signupLink={event?.signupLink || ""}
              price={event?.price || ""}
            />

            <EventAgenda agenda={agenda} />

            <EventSpeakers speakers={speakers} />
          </div>

          <EventSidebar
            shareUrls={
              shareUrls || {
                facebook: "",
                twitter: "",
                linkedin: "",
                currentUrl: currentUrl,
              }
            }
            relatedEvents={relatedEvents}
            linkCopied={linkCopied}
            onCopyLink={handleCopyLink}
            calendarEvent={calendarEvent || undefined}
            contacts={
              Array.isArray(event?.contacts) ? (event?.contacts as any[]) : []
            }
          />
        </div>
      </div>
    </StatusHandler>
  );
};

export default EventPage;
