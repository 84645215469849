import React from "react";
import styles from "./Timeline.module.scss";
import {
  TimelineStep as TimelineStepComponent,
  TimelineStep,
} from "./TimelineStep";

interface TimelineProps {
  steps: TimelineStep[];
}

const Timeline: React.FC<TimelineProps> = ({ steps }) => {
  return (
    <div className={styles.timelineContainer}>
      {steps.map((step, index) => (
        <TimelineStepComponent
          key={step.step || index}
          {...step}
          isFirst={index === 0}
          isLast={index === steps.length - 1}
        />
      ))}
    </div>
  );
};

export default Timeline;
